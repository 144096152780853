import { lazy, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './components/@vuexy/rippleButton/RippleButton'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ToastContainer } from 'react-toastify'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQuery } from 'src/configs/reactQuery'
import { useDBObjectObserver } from 'src/components/dbObjectObserver'
import { Logo } from 'src/components/2022/logo'
import { fetchComponents } from 'src/redux/actions/components'
import { fetchApp } from 'src/redux/actions/app'
import { setRoleFilter, setUnitFilter, setUserFilter } from 'src/redux/actions/filters'
import { theme } from './assets/muiTheme'
import { disableInputNumberScrollingHTML, fetchInitialDataAndSetDefaultFilters } from './utility/helperFunctions'
import { history } from './history'
import { authFetch } from './configs/fetchAPI'
import { fetchUser } from './components/fetchUser'
import 'react-toastify/dist/ReactToastify.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { GET_UNITS } from 'src/redux/actions/units/actionTypes'
import { GET_USER } from 'src/redux/actions/auth/actionTypes'
import * as Sentry from '@sentry/browser'
import { APP_MODE } from 'src/env'

const error500 = lazy(() => import('src/views/pages/misc/error/500'))
const Router = lazy(() => import('src/routes'))

disableInputNumberScrollingHTML()

export const App = () => {
  const user = useSelector((state) => state.auth?.user)
  const dispatch = useDispatch()
  const fetchAppDispatched = () => dispatch(fetchApp())
  const fetchComponentsDispatched = () => dispatch(fetchComponents())
  const setRoleFilterDispatched = (value) => dispatch(setRoleFilter(value))
  const setUnitFilterDispatched = (value) => dispatch(setUnitFilter(value))
  const setUserFilterDispatched = (value) => dispatch(setUserFilter(value))
  console.log('user', user)
  useDBObjectObserver('profileSideBar', 1, { isSidebarOpen: true })

  const isAnonymousLink =
    '/login' == window.location.pathname ||
    '/quiz_hackathon' == window.location.pathname ||
    '/bq' == window.location.pathname ||
    '/register' == window.location.pathname ||
    '/tq' == window.location.pathname ||
    '/lq' == window.location.pathname ||
    '/tq-devops' == window.location.pathname ||
    '/interview-resigned' == window.location.pathname ||
    '/interview-fired' == window.location.pathname ||
    window.location.pathname.startsWith('/sign')
      console.log('user', user)
  useEffect(() => {
    if (isAnonymousLink) return
    fetchUser().then((userData) => {
      if (!userData.id) {
        history.push('/login')
      }
      dispatch({ type: GET_USER, payload: userData })
    })
  }, [])

  useEffect(() => {
    if (!user.id) return
    if (APP_MODE !== 'development') {
      Sentry.setUser({ id: user?.id, email: user?.email })
    }

    authFetch(
      `/api/auth/accounts/?fields=stock_options_awarded,stock_options_fined,stock_options_vested_till_end_of_month,onboarding_rate&search=${user.email}`,
      { method: 'GET' },
    )
      .then((response) => response.json())
      .then((json) => dispatch({ type: GET_USER, payload: { ...user, ...json[0] } }))
      .then(() =>
        fetchInitialDataAndSetDefaultFilters(
          setUnitFilterDispatched,
          setRoleFilterDispatched,
          setUserFilterDispatched,
          fetchAppDispatched,
          fetchComponentsDispatched,
          user,
        ),
      )
      .then(() =>
        authFetch('/api/v2/query/gq/', { // FIX ASAP
          method: 'POST',
          body: {
            Unit: { select: ['id', 'unit', 'unit_mission', 'mptt_level', { parent: { select: ['id', 'unit'] } }, { children: { select: ['id'] } }] },
          },
        }),
      )
      .then((response) => 200 === response.status && response.json())
      .then((json) => {
        const maped = json.rows.map((r) => ({ ...r, level: r.mptt_level, children: r.children.map((c) => c.id) }))
        dispatch({ type: GET_UNITS, payload: maped })
      })
      console.log('user', user)
  }, [user.id])

  if (!isAnonymousLink && !user.id) return <Logo />

  return (
    <ReactQuery>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorBoundary FallbackComponent={error500}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
              <ToastContainer />
            </LocalizationProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReactQuery>
  )
}
